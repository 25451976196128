
import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch } from "vue";
import type { PropType } from "vue";
import { useTicketStore } from "@/store/TicketStore";
import Comments from "@/views/tickets/components/Comments.vue";
import moment from "moment";

interface Ticket {
  cnpj: string,
  store_name: string,
  store_manager: string,
  solicitante: string,
  store_contact: string,
  erp: boolean,
  possui_conecta: number | null,
  title: string,
  id_status: number | null,
  id_category: number | null,
  report_date: string,
  description: string,
  resolution: string,
}

export default defineComponent({
  name: "ModalTickets",
  components: {
    Comments
},
  props: {
    usuarioProp: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
    ticketProp: Object as PropType<any>,
  },

  setup(props) {

    const store = useTicketStore();
    const arrayLojas: Ref<any[]> = ref([]);
    const loadSave: Ref<boolean> = ref(false);
    const loadExcluir: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);

    const clikouResetarSenha: Ref<boolean> = ref(false);
    const novaSenha: Ref<string> = ref('indicar');
    const adicionarUsuarioEmNovaLoja: Ref<boolean> = ref(false)

    const arrayComments: Ref<any[]> = ref([]);
    const idLoja: Ref<any> = ref(null);

    const ticket: Ref<Ticket> = ref({
      cnpj: "",
      store_name: "",
      store_manager: "",
      solicitante: "",
      store_contact: "",
      erp: false,
      possui_conecta: null,
      title: '',
      id_status: null,
      id_category: null,
      report_date: '',
      description: '',
      resolution: '',
    })

    // ---------------------------------

    async function getComments(){
        const { data } = await Api.get("comments", {id_ticket: store.getTicket.id});
        arrayComments.value = data
      }

    watch(
      () => store.getTicket, 
      () => {
        ticket.value = {...store.getTicket }
        getComments()
        }, 
      { deep: true }
      )

    return {
      loadSave,
      loadExcluir,
      arrayLojas,
      adicionarUsuarioEmNovaLoja,
      clikouResetarSenha,
      novaSenha,
      idLoja,
      dialogVisible,
      ticket,
      arrayComments,
      moment
    };
  },
});
