
import { defineComponent, Ref, ref, onMounted, watch, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import Comments from "@/views/tickets/components/Comments.vue"
import { useRouter, useRoute } from "vue-router";
import useAlert from '@/composables/Alert'
import Api from "@/services/Api";
import moment from "moment";
import ModalTickets from "@/views/tickets/components/ModalTickets.vue"
import ModalJira from "@/views/tickets/components/ModalJira.vue"
import { Modal } from 'bootstrap'
import { useTicketStore } from '@/store/TicketStore'
import Loader from '@/components/Loader.vue'
import { useLoaderStore } from "@/store/LoaderStore";
import { useCommentStore } from "@/store/CommentStore";
import { TOptions, uploadMediaFromClient } from "@/services/AzureBlobService";
import { ElMessage, ElMessageBox } from 'element-plus'
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import { useSocketStore } from '@/store/SocketPinia';
import { useOpenAudioNotification } from "@/store/OpenAudioNotification";

interface InfoClient {
  cnpj: string,
  store_name: string,
  store_manager: string,
  solicitante: string,
  store_contact: string,
  erp: string,
  possui_conecta: string | null
}

interface InfoProblema {
  title: string,
  id_status: number | string,
  id_category: number | null,
  report_date: string,
  periodo: string,
  description: string,
  resolution: string,
  email: string,
}

interface Jira {
  key: string,
  titulo: string,
  descricao: string,
  tipo: string
}

interface Comment {
  comentario: string,
  exibirChat: boolean
}

interface FormObjectInterface {
  campos: Array<{
    type: 'input' | 'textarea' | 'date' | 'select';
    label: string | null;
    value: string | null;
    options?: Array<string | null> | null;
  }> | null;
  titulo: string | null;
}

export function formatCNPJ(number: string | number): string {
  if (typeof number === "number")
    number = number.toString();

  const cleanedNumber = number.replace(/\D/g, '');

  if (cleanedNumber.length === 14) {
    const part1 = cleanedNumber.substring(0, 2);
    const part2 = cleanedNumber.substring(2, 5);
    const part3 = cleanedNumber.substring(5, 8);
    const part4 = cleanedNumber.substring(8, 12);
    const part5 = cleanedNumber.substring(12, 14);

    return `${part1}.${part2}.${part3}/${part4}-${part5}`;
  }

  return cleanedNumber;
}

export function formatPhoneNumber(number: string | number): string {
  if(typeof number === "number")
    number = number.toString()

  const cleanedNumber = number.replace(/\D/g, '');

  if (cleanedNumber.length === 11) {
    const areaCode = cleanedNumber.substring(0, 2);
    const firstDigit = cleanedNumber.charAt(2);
    const firstPart = cleanedNumber.substring(3, 7);
    const secondPart = cleanedNumber.substring(7);

    return `(${areaCode}) ${firstDigit} ${firstPart}-${secondPart}`;
  } else if (cleanedNumber.length === 10) {
    const areaCode = cleanedNumber.substring(0, 2);
    const firstPart = cleanedNumber.substring(2, 6);
    const secondPart = cleanedNumber.substring(6);

    return `(${areaCode}) ${firstPart}-${secondPart}`;
  } else {
    return cleanedNumber
  }
}

const INIT_INFO_CLIENT: InfoClient = {
  cnpj: "",
  store_name: "",
  store_manager: "",
  solicitante: "",
  store_contact: "",
  erp: "",
  possui_conecta: "1"
}

export default defineComponent({
  name: "Tickets",

  components: {
    Title,
    Button,
    Comments,
    ModalJira,
    ModalTickets,
    ElConfigProvider,
  },

  setup() {
    const { showTimeAlert } = useAlert();
    const store = useTicketStore();
    const loaderStore = useLoaderStore();
    const isLoadingTicket: Ref<boolean> = ref(false)
    const isLoadingComments: Ref<boolean> = ref(false)
    const isDisabled: Ref<boolean> = ref(false)
    const commentStore = useCommentStore();
    const router = useRouter();
    const route = useRoute();
    const loadCreate: Ref<boolean> = ref(false);
    const showLastReports: Ref<boolean> = ref(false);
    const createTicketBool: Ref<boolean> = ref(false);
    const centerDialogVisible: Ref<boolean> = ref(false);
    const confirmExcluirTicket: Ref<boolean> = ref(false);
    const clickedSendComment: Ref<boolean> = ref(false)
    const optionsSaving: Ref<boolean> = ref(false);
    const sendToJira: Ref<boolean> = ref(false);
    const jiraRequired: Ref<boolean> = ref(false);
    const ativaSelectStatus: Ref<boolean> = ref(false);
    const ticket: Ref<any> = ref({});
    const arrayStores: Ref<any> = ref([]);
    const id_ticket: Ref<string | string[] | null> = ref(null)
    const arrayStatus: Ref<any> = ref([]);
    const reportLoading: Ref<boolean> = ref(false);
    const arrayTickets: Ref<any> = ref([]);
    const arrayComments: Ref<any> = ref([]);
    const arrayCategory: Ref<any> = ref([]);
    const itemsPerPage: Ref<number> = ref(5);
    const page: Ref<number> = ref(1);
    const urlImagesToPreview: Ref<any> = ref([]);
    const comment: Ref<Comment> = ref({
      comentario: '',
      exibirChat: false
    });
    const idUserTicket: Ref<any> = ref();
    const isOwner: Ref<any> = ref();
    const openForm: Ref<boolean> = ref(false)

    const uploadedFiles: Ref<any> = ref([]);
    const fileCount: Ref<any> = ref(0)

    const id_project: Ref<any> = ref(localStorage.getItem('id_project')); //numero do projeto
    
    const eventName: Ref<string|null> = ref(null)
    const socketStore = useSocketStore();
    const AudioNotification = useOpenAudioNotification();

    const infoClient: Ref<InfoClient> = ref({ ...INIT_INFO_CLIENT });

    const infoProblema: Ref<InfoProblema> = ref({
      title: '',
      id_status: "",
      id_category: null,
      report_date: moment().format('YYYY-MM-DD'),
      periodo: '',
      description: '',
      resolution: '',
      email: '',
    })

    const formDinamico: Ref<FormObjectInterface> = ref({
      campos: [
        { type: 'input', label: null, value: null },
        { type: 'textarea', label: null, value: null },
        { type: 'date', label: null, value: null },
        { type: 'select', label: null, value: null, options: [null] },
      ],
      titulo: null,
    });

    const addToJira: Ref<Jira> = ref({ key: '', titulo: '', descricao: '', tipo: '' })

    function receiveConfigJira(obj) {
      addToJira.value = { key: obj.key, titulo: infoProblema.value.title, descricao: infoProblema.value.description, tipo: obj.tipo }
    }

    watch(
      () => addToJira.value,
      function changeChecked() {
        if (addToJira.value.key && addToJira.value.tipo) optionsSaving.value = true
        else optionsSaving.value = false
      }, { deep: true }
    )

    watch(
      () => infoProblema.value,
      () => addToJira.value = { ...addToJira.value, titulo: infoProblema.value.title, descricao: infoProblema.value.description },
      { deep: true }
    )

    async function getAllStores() {
      // loaderStore.open();

      const object:{
        cnpj: string | null
        project: number | null
      } = {
        cnpj: infoClient.value.cnpj,
        project: null,
      }

      switch(+id_project.value) {
        case 1:
          object.project = 1
          break;
        case 3:
          object.project = 3
          break;
      }

      try {
        const { data } = await Api.get("getPdvs", { ...object });
  
        if (data.response === null) {
          loaderStore.close();
          return
        }
        // if(!data.response.data.length){
        //   loaderStore.close();
        //   return showTimeAlert('Loja não encontrada', "error")
        // }
        // loaderStore.close();

        const storeList = data.response.data.map((el, i) => ({
          ...el,
          key: el?.codConcessionaria || el?.id || i
        }));

        return storeList
      } catch (error) {
        console.error(error)
      }
      return null
    }


    async function getStore() {
      const response = await getAllStores()

      if (response) {
        const store = response[0]
        infoClient.value.store_contact = store.telefone
        // infoClient.value.store_name = store.store_name
        infoClient.value.store_name = store.nomeFantasia
        //possui conecta padrão true para indicar
        infoClient.value.possui_conecta = "1"
        if (store.idErp !== null) {
          infoClient.value.erp = store.erp
        }
      }
    }

    function openInput() {
      const input = document.querySelector('#input-file') as HTMLElement;

      input.click()
    }

    async function getStatus() {
      const { data } = await Api.get("status")
      arrayStatus.value = data
      if(Array.isArray(arrayStatus.value) && arrayStatus.value.length > 0)
        infoProblema.value.id_status = arrayStatus.value[0].id
    }

    const messageBox = (id_ticket: number, feedback = 'Ticket criado com sucesso') => {
      ElMessageBox.alert(
        `Código do ticket: TCK-${id_ticket}`,
        feedback,
        {
          confirmButtonText: 'OK',
          type: 'success',
          center: true,
          callback: () => {
            router.back()
          }
        }
      )
    }

    const handleMessageBox = (id: number) => id_ticket.value ? messageBox(id, 'Ticket atualizado com sucesso') : messageBox(id)

    async function createTicket() {
      if (sendToJira.value) {
        if (!infoProblema.value.description || !infoProblema.value.title) {
          centerDialogVisible.value = false
          jiraRequired.value = true
          return showTimeAlert("Título e descrição sao obrigatórios para criar o card no JIRA", "error");
        }
      }

      centerDialogVisible.value = false

      const date = infoProblema.value.report_date ? formatData(infoProblema.value.report_date) : null;
      infoProblema.value.report_date = date as any

      const id_ticket = route.params.id
      if (formDinamico.value && formDinamico.value.campos?.length) {
        const selects = formDinamico.value.campos.filter(item => item.type === 'select');
        const value = selects.map(item => item.value);
        infoProblema.value.id_status = value[0] as any || infoProblema.value.id_status
      }
      const dados = { ...infoClient.value, ...infoProblema.value, id_project: id_project.value, id_ticket,  formResponse: JSON.stringify({ ...formDinamico.value, ...infoProblema.value }) }

      // if(!infoProblema.value.title || !infoProblema.value.description){
      //   return showTimeAlert('Preencha os campos obrigatórios', "error");
      // }

      loadCreate.value = true

      if (id_project.value == 3) /** the pontuei project does not use this fields */ {
        dados.erp = ""
        dados.possui_conecta = "0"
      }

      const { data, error, message } = await Api.post("ticket", dados);
      if (sendToJira.value) {
        const res = await Api.post("createCard", { ...addToJira.value })
      }

      loadCreate.value = false
      jiraRequired.value = false
      if (error) {
        return showTimeAlert("Erro ao salvar ticket!", "error");
      } else {
        // messageBox(data.id)
        handleMessageBox(data.id as number)
      }
      // showTimeAlert(message)
    }

    async function getTickets() {
      reportLoading.value = true
      const { data } = await Api.get("tickets", { page: page.value, perPage: itemsPerPage.value })
      arrayTickets.value = data.tickets
      reportLoading.value = false
    }

    async function getTicketById() {
      isLoadingTicket.value = true

      if (id_ticket.value) {
        isDisabled.value = true
        const { data, error } = await Api.get('getTicketById', { id_ticket: id_ticket.value })

        if(error == true) {
          router.push('/listaTickets')
          showTimeAlert("Ticket não encontrado", "warning")
        }
        //verifica se tem o id do ticket na rota, se sim busca o usuario no banco pega as informaçoes e preenche os inputs
        idUserTicket.value = data.id_user
        const id_user = JSON.parse(localStorage.getItem('user') as any).id

        if(id_user == idUserTicket.value) isOwner.value = true

        const { cnpj, store_name, store_manager, solicitante, store_contact, erp, possui_conecta, title, id_status, id_category, report_date, periodo, description, resolution, email } = data

        infoClient.value = { cnpj, store_name, store_manager, solicitante, store_contact, erp, possui_conecta }

        infoProblema.value = { title, id_status, id_category, report_date: report_date ? report_date : null, periodo, description, resolution, email }

        await findForm();
        if (data.ticket_json) {

          formDinamico.value = JSON.parse(data.ticket_json);
          const selects = formDinamico.value &&  formDinamico.value.campos && formDinamico.value.campos.filter(item => item.type === 'select');
          ativaSelectStatus.value = selects?.length ? false : true;
          openForm.value = true;
        }
        if(id_category == 6) openForm.value = true;
      }
      isLoadingTicket.value = false
      getComments()
    }

    async function deleteTicket(id_ticket) {

      try {
        const data = await Api.delete(`ticket/${id_ticket}`)
        goBack()
        return showTimeAlert("Ticket deletado com sucesso", "success")
      } catch (error) {
        console.log(error)
      }
    }

    async function createComment() {
      clickedSendComment.value = true
      if (id_ticket.value) {
        const response = await commentStore.createComment(id_ticket.value, comment.value.comentario, comment.value.exibirChat)
        const idComment = response.comment.id
        //enviar imagem passando id do comentario criado
        if (uploadedFiles.value.length > 0) {
          await sendImage(idComment)
        }
        comment.value.comentario = ''
        clickedSendComment.value = false
      }
      getComments()
    }

    async function getComments() {
        try{
            isLoadingComments.value = true  
    
            if (id_ticket.value) {
                await commentStore.getCommentsById(route.params.id)
                arrayComments.value = commentStore.getComments
                arrayComments.value.sort((a, b) => b.id - a.id)
            }
        }catch(erro){
            console.log("erro", erro)
        }finally{
            isLoadingComments.value = false
        }
    }

    async function getCategories() {
      // console.log('ola')
      const { data } = await Api.get('categories');
      arrayCategory.value = data
    }

    //criar get para pegar fotos dos comentarios

    function statusColor(status: string) {
      let color: string;
      switch (status) {
        case "Aberto":
          color = "#F64E60"
          break;
        case "Andamento":
          color = "#3699FF"
          break;
        case "Desenvolvimento":
          color = "#3699FF"
          break;
        case "Retorno Cliente":
          color = "#3699FF"
          break;
        case "Fechado":
          color = "#1BC5BD"
          break;
        default:
          color = "#000000";
      }
      return color;
    }

    function formatData(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss');
    }

    async function filesChange(event) {
      const files = Array.from(event.target.files)

      try {
        //populate list of urls to preview
        //populate list of files 
        files.forEach(file => {
          const url = URL.createObjectURL(file as any)
          uploadedFiles.value.push(file)
          urlImagesToPreview.value.push(url)
        });
      } catch (error) {
        console.log("error", error)
      }

    }

    async function sendImage(id_comment) {
      //enviar para azure
      for (const file of uploadedFiles.value) {
        try {
          const options: TOptions = {
            blobHTTPHeaders: {
              blobContentType: file.type
            }
          }
          const fileName = `${file.name}${new Date().getTime()}`
          const { _response } = await uploadMediaFromClient(file, fileName, options)
          const url = _response.request.url
          //enviar para banco de dados
          await Api.post('urlComment', { id_comment, url })
          clearImageLists()

        } catch (error) {
          //tratar erro
          console.log(error)
        }
      };
    }

    function openModal(id: string) {
      const ticketModal = new Modal(document.getElementById(`${id}`))
      ticketModal.show()
    }

    function getInfoTicket(ticket) {
      const infoTicket = ticket
      // tratar dados
      store.sendTicket(infoTicket)
    }
    // async function isOwnerTicket() {
    //   const { data } = await Api.get('getAuthenticatedUser')
    //   console.log("user", data)
    //   console.log("idUserTicke", idUserTicket.value)

    //   if (!data.user) return
    //   isOwner.value = data.user.id === idUserTicket.value
    // }

    function deletePreviewImage(indice) {
      urlImagesToPreview.value.splice(indice, 1)
      uploadedFiles.value.splice(indice, 1)
    }

    function clearImageLists() {
      urlImagesToPreview.value = []
      uploadedFiles.value = []
    }

    function goBack() {
      store.clearFilter()
      router.back()
    }

    async function findForm() {

        const { data } = await Api.get('getForm', { id: infoProblema.value.id_category })
        
        if (!data) {
            if (!route.params.id) {
                showTimeAlert("Exibindo form padrão", "warning")
            }
            openForm.value = false;
            return
        }
        
        if (!route.params.id) {

            if (JSON.parse(data).dynamicForm) {
                formDinamico.value.campos = JSON.parse(data).dynamicForm.campos;
                addToJira.value.key = JSON.parse(data).jiraOptions.key;
                addToJira.value.tipo = JSON.parse(data).jiraOptions.tipo;
                openForm.value = true;
            } else if (JSON.parse(data).ticket_json) {
                formDinamico.value.campos = JSON.parse(data).ticket_json.campos;
                addToJira.value.key = JSON.parse(data).jiraOptions.key;
                addToJira.value.tipo = JSON.parse(data).jiraOptions.tipo;
                openForm.value = true;
            } else {
                formDinamico.value = JSON.parse(data);
                openForm.value = true;
            }
        }
    }

    const transformArrayDeObjetos = (arr: string[]) => arr.map(str => {
      const [labelPart, valuePart] = str.split(", ");
      const [labelKey, labelValue] = labelPart.split(": ");
      const [valueKey, valueValue] = valuePart.split(": ");
      return { label: labelValue.trim(), value: valueValue.trim() };
    });

    watch(
      () => itemsPerPage.value,
      () => {
        getTickets()
      }
    )

    watch(() => infoClient.value.store_name, () => {
      if (!infoClient.value.store_name)
        return infoClient.value = { ...INIT_INFO_CLIENT };

      if(arrayStores.value.length){
        const finded = arrayStores.value.filter((store) => store.nomeFantasia === infoClient.value.store_name)
        const { nomeFantasia, cnpj, erp, responsavel, telefone } = finded[0]
        infoClient.value = {
          ...infoClient.value,
          cnpj: formatCNPJ(cnpj),
          store_contact: formatPhoneNumber(telefone),
          store_manager: responsavel,
          store_name: nomeFantasia,
          erp 
        };
      }

    })


    
    function ativaEventoSocket(){
      socketStore.startSocket();
      listeningEvent();
    }

    function listeningEvent(){
      let idTicketAtual = parseInt(id_ticket.value as string);
      eventName.value = `${process.env.VUE_APP_ENV}-newComment-${localStorage.getItem('id_project') || '1'}-${idTicketAtual}`
      socketStore.newListen({
        name: eventName.value,
        callbackFunction: (data) => {
            getComments();
        },
      });
    }

    watch(() => AudioNotification.status, (newStatus) => {
      ativaEventoSocket();
    });


    onBeforeMount(() => {
      // loaderStore.open()
      if (route.params.id) return;
      createTicketBool.value = true
    })

    onMounted(async () => {
      if (route.params.id) id_ticket.value = route.params.id;
      getTicketById();
      getTickets();
      getStatus();
      getCategories();
      arrayStores.value = await getAllStores()
      // isOwnerTicket()
      // const observer = new IntersectionObserver(entries => {
      //   if(entries[0].isIntersecting) {
      //     itemsPerPage.value = itemsPerPage.value + 5
      //   }
      // }).observe(document.querySelector('#load-reports') as any)
    })

    watch(() => route.params.id,async () => {
      if (route.params.id) id_ticket.value = route.params.id;
      getTicketById();
      getTickets();
      getStatus();
      getCategories();
      arrayStores.value = await getAllStores()
    });

    return {
      router,
      infoClient,
      arrayStores,
      getAllStores,
      getStatus,
      getStore,
      infoProblema,
      arrayStatus,
      createTicket,
      arrayTickets,
      loadCreate,
      statusColor,
      moment,
      comment,
      openModal,
      createComment,
      arrayComments,
      ticket,
      getInfoTicket,
      goBack,
      isLoadingTicket,
      isLoadingComments,
      id_ticket,
      reportLoading,
      arrayCategory,
      itemsPerPage,
      filesChange,
      openInput,
      urlImagesToPreview,
      deletePreviewImage,
      optionsSaving,
      receiveConfigJira,
      centerDialogVisible,
      sendToJira,
      route,
      deleteTicket,
      isOwner,
      jiraRequired,
      findForm,
      formDinamico,
      openForm,
      ptBr,
      transformArrayDeObjetos,
      createTicketBool,
      showLastReports,
      addToJira,
      ativaSelectStatus,
      confirmExcluirTicket,
      clickedSendComment,
      isDisabled,
      id_project,
    };
  },
});
