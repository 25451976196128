
import useEmitter from "@/composables/Emmiter";
import Api from "@/services/Api";
import { defineComponent, onBeforeUpdate, onMounted, ref, Ref, watch } from "vue";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";

interface Projeto {
  key: string;
  name: string;
}

export default defineComponent({
  name: "ModalJira",
  emits: ['receiveConfigJira'],
  props: { allLojas: Array, count: Number },
  setup(props, {emit}) {
    const emitter = useEmitter();
    const projetos: Ref<Projeto[]> = ref([]);
    const projDefined: Ref<any> = ref("");
    const typeDefined: Ref<any> = ref("");
    const issues: Ref<any> = ref(['Task', 'Story', 'Bug', 'Tarefa'])
    const fullHistorico: Ref<number | string> = ref(20)

    async function getProjects() {
      try {
        const { data } = await Api.get("getAllProjects")
        projetos.value = data

      } catch (error) {
        console.log(error);
      }
    }

    watch(()=> [typeDefined.value, projDefined.value], ()=> emit('receiveConfigJira', {key: projDefined.value, tipo: typeDefined.value}))
    watch(() => props.count, () => getProjects())
    onMounted(()=> getProjects())

    return { projetos, projDefined, typeDefined, ElConfigProvider, ptBr, issues };
  },
});
