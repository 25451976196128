
// base url

import store from "@/store";
import { destroyUsuarioAuth } from "./AuthService";
import { Actions } from "@/store/enums/StoreEnums";

const baseUrl = process.env.VUE_APP_URL_API

function getToken() {
    const token = localStorage.getItem('token_usuario');
    return token
};
  
function getIdProject() {
    const id_project = localStorage.getItem('id_project');
    return id_project
};


interface Http {
    (url: string, object?: any, options?: any): Promise<JSON | any>
}

/**
 * [someFunction description]
 * @param  {[type]} arg1 [description]
 * @param  {[type]} arg2 [description]
 * @return {[type]}      [description]
 */
interface ApiType {
    get: Http,
    post: Http
    put: Http, 
    delete: Http, 
    // auth: <JSON = any>(url: string, object: any) => Promise<JSON>, 
    // signOut: () => Promise<void>
}

const header = () => {
    const header = {
        'Allow-Access-Control-Origin': '*',
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${getToken()}`,
        'id-project': getIdProject() || '' as any
    }
    return header;
}

function fetchApi():ApiType {

    const getMethod: Http = async <JSON = any>(url: string, object?: any, options?: any): Promise<JSON | any> => {
        
        const response = await fetch(baseUrl + url + `${ object ? `?${new URLSearchParams(object)}` : ''}`, {
            method: 'GET',
            mode: 'cors',
            headers: header()
        })

        if(response.status === 401) {
            destroyUsuarioAuth()
            store.dispatch(Actions.LOGOUT);
        }

        if(options?.responseType) return response[options.responseType]()

        return await response.json()
          
    }
    
    const postMethod: Http = async <JSON = any>(url: string, object: any, options?: any): Promise<JSON> => {
            
        const response = await fetch(baseUrl + url, {
            method: 'POST',
            mode: 'cors',
            headers: header(),
            body: JSON.stringify(object)
        })

        if(options?.responseType) return response[options.responseType]()

        return await response.json()
        
    }

    const putMethod: Http = async <JSON = any>(url: string, object: any, options?: any): Promise<JSON> => {
            
        const response = await fetch(baseUrl + url, {
            method: 'PUT',
            mode: 'cors',
            headers: header(),
            body: JSON.stringify(object)
        })

        if(options?.responseType) return response[options.responseType]()

        return await response.json()
        
    }
    // const putMethod:Http = async <JSON = any>(url: string, object: any): Promise<JSON> => {

    // }
    
    const deleteMethod: Http = async <JSON = any>(url: string, object?: any): Promise<any> => {
            
        const response = await fetch(baseUrl + url + `${ object ? `?${new URLSearchParams(object)}` : ''}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: header(),
        })
        return response.status === 204 ? response : await response.json()
    }

    // async function authMethod<JSON = any>(url: string, object: any,): Promise<JSON> {

    //     const response = await fetch(url, {
    //         method: 'POST',
    //         mode: 'cors',
    //         headers: {
    //             'Content-Type': 'application/json;charset=utf-8'
    //         },
    //         body: JSON.stringify(object)
    //     })
    //     const data = await response.json()
    //     await setCookie('auth', data.accessToken, 360)
    //     return data
    // }

    // async function signOutMethod(): Promise<void> {
    //     try{

    //         await deleteCookie('auth')
    //         if(typeof window !== 'undefined') window.location.reload()

    //     } catch(err){

    //         console.log('An error occurred when logging out: ', err)
    //         if(typeof window !== 'undefined') window.location.reload()

    //     }
    // }

    return {
        get: getMethod,  
        post: postMethod, 
        put: putMethod, 
        delete: deleteMethod, 
        // auth: authMethod, 
        // signOut: signOutMethod
    }
}

/**
 * `Api` is a fetch factory that provide simple http methods.
 * 
 * It used the same API RESTful design pattern.
 *
 * ```js
 * await Api.post('www.example.products', { name:'table' })
 * // { id:1, name: 'table' }, 
 * 
 * await Api.get('www.example.users/')
 * //[ { name: 'John Doe' },  { name: 'Gabriel Henrique' } ]
 * 
 * await Api.get('www.example.products', { id:1 })
 * // { name: 'Gabriel Henrique' }, 
 * 
 * await Api.delete('www.example.products', { id: 1 })
 * //    { id:1, name:'table' }
 * ```
 *
 * See `www.google.com` for more information.
 * @since v0.1 by [`Gabriel henrique`](https://github.com/gab-h3nrique)
 */
const Api = fetchApi()

export default Api

//--------------------------------------

// function absoluteUrl() {
//     if(typeof window !== 'undefined') {
//         const url = window.location.href
//         return url
//         // return window.location.hostname;
//     }
// }