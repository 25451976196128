import { BlobServiceClient } from '@azure/storage-blob';

export type TOptions = {
  blobHTTPHeaders: {
    blobContentType: string;
  };
}

export async function uploadMediaFromClient(file: File, blobName: string, options?: TOptions) {
  const blobServiceClient = new BlobServiceClient(`https://${process.env.VUE_APP_AZURE_STORAGE_NAME}.blob.core.windows.net/${process.env.VUE_APP_AZURE_SAS_TOKEN}`);
  const containerClient = blobServiceClient.getContainerClient(process.env.VUE_APP_AZURE_STORAGE_CONTAINER!);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);

  return await blockBlobClient.uploadData(file, options);
}

export async function deleteMediaFromClient(blobName: string) {
  const blobServiceClient = new BlobServiceClient(`https://${process.env.VUE_APP_AZURE_STORAGE_NAME}.blob.core.windows.net/${process.env.VUE_APP_AZURE_SAS_TOKEN}`);
  const containerClient = blobServiceClient.getContainerClient(process.env.VUE_APP_AZURE_STORAGE_CONTAINER!);

  return await containerClient.deleteBlob(blobName)
}

