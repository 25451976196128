
import { defineComponent, onMounted, Ref, ref } from "vue"
 
 
export default defineComponent({
  name: "Button",
    props: {
      variant: String,
      text: String,
    },
    setup(props){

      const variant: Ref<String> = ref('')

      onMounted(() => {
        if(!props.variant){
           variant.value = "btn-light-danger bg-white"
        }
        if(props.variant === 'primary'){
           variant.value = "btn-light-danger"
        }
        if(props.variant === 'secondary'){
           variant.value = "btn-danger"
        }

      })
    
      return{
        variant,
      }
    }
 })

